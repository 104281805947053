import { ValueOf } from 'routing/constants';

export enum PriceManagementTabsEnum {
  RATE = 'rates',
  YIELD = 'yields',
  WEATHER = 'weather',
  PRICE_OVERRIDE = 'price-overrides',
}

export type PriceManagementTabType = ValueOf<PriceManagementTabsEnum>;

