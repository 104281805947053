export enum EmailSettingsTabsEnum {
  SURVEY_REPORT = 'survey-report',
  SURVEY_SETTINGS = 'survey-settings',
  MARKETING = 'marketing',
  REMINDER = 'reminder',
  REPORT = 'report',
}

export const SurveyMetaData = {
  subjects: [{ name: 'Customer Satisfaction' }],
};

