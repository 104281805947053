import { DiscountType } from '../time-slot/types';
import type { WeekDayType } from './types';

export const DEFAULT_OVERRIDE_DAYS: Record<WeekDayType, number> & { key: string } = {
  key: 'key',
  Sunday: 0,
  Monday: 0,
  Tuesday: 0,
  Wednesday: 0,
  Thursday: 0,
  Friday: 0,
  Saturday: 0,
  Holiday: 0,
};

export const getDefaultOverrideDays = (discountType: DiscountType): Record<WeekDayType, number> => {
  const value: number = discountType === 'Percent' ? 100 : 0;
  return {
    Sunday: value,
    Monday: value,
    Tuesday: value,
    Wednesday: value,
    Thursday: value,
    Friday: value,
    Saturday: value,
    Holiday: value,
  };
};

