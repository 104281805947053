export const TradeManagerFixture = [
  {
    '08.11.2021': [
      {
        id: 1,
        holes: '17',
        date: '08.11.2021',
        players: '1-4',
        time: '11:00 PM',
        price: 45.99,
        cart: 'Included',
        trade: true,
        displayed: true,
      },
      {
        id: 2,
        holes: '18',
        date: '08.11.2021',
        players: '1-4',
        time: '11:00 PM',
        price: 45.99,
        cart: 'Included',
        trade: true,
        displayed: true,
      },
      {
        id: 3,
        holes: '18',
        date: '08.11.2021',
        players: '1-4',
        time: '11:00 PM',
        price: 45.99,
        cart: 'Included',
        trade: true,
        displayed: true,
      }],
  },
  {
    '09.11.2021': [
      {
        id: 4,
        holes: '18',
        date: '08.11.2021',
        players: '1-4',
        time: '11:00 PM',
        price: 45.99,
        cart: 'Included',
        trade: true,
        displayed: true,
      },
      {
        id: 5,
        holes: '18',
        date: '08.11.2021',
        players: '1-4',
        time: '11:00 PM',
        price: 45.99,
        cart: 'Included',
        trade: true,
        displayed: true,
      },
      {
        id: 6,
        holes: '18',
        date: '08.11.2021',
        players: '1-4',
        time: '11:00 PM',
        price: 45.99,
        cart: 'Included',
        trade: true,
        displayed: true,
      }]
  },
  {
    '10.11.2021': [
      {
        id: 7,
        holes: '18',
        date: '08.11.2021',
        players: '1-4',
        time: '11:00 PM',
        price: 45.99,
        cart: 'Included',
        trade: true,
        displayed: true,
      },
      {
        id: 8,
        holes: '18',
        date: '08.11.2021',
        players: '1-4',
        time: '11:00 PM',
        price: 45.99,
        cart: 'Included',
        trade: true,
        displayed: true,
      },
      {
        id: 9,
        holes: '18',
        date: '08.11.2021',
        players: '1-4',
        time: '11:00 PM',
        price: 45.99,
        cart: 'Included',
        trade: true,
        displayed: true,
      }]
  }
];
